<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="consultations-section">
    <div class="container" ref="libraryContainer">
      <div class="about-title-sec">
        <h3>الاستشارات</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>
      <div class="consultations-flex">
        <div class="consultations-blogs">
          <div class="about-collapse">
            <div v-for="(cons, index) in consultations" :key="index">
              <div class="faq-div wow fadeInUp" data-wow-delay="0.2s">
                <h4 class="faq-title fixall" :class="{ active: cons.isOpen }" @click="toggleFaq(index)">
                  <p class="faq-text">
                    {{cons.title}}
                  </p>
                  <i class="uil uil-plus"></i>
                </h4>
                <transition name="cons">
                  <div class="faq-body" v-if="cons.isOpen" style="display: block;">
                    <div class="faq-flex">
                      <div v-if="cons.image" class="faq-flex-photo">
                        <img :src="cons.image" class="img-fluid" alt="#">
                      </div>
                      <div class="faq-flex-title">
                        <p>
                          <span v-if="cons.showFullDetails">{{ cons.details }}</span>
                          <span v-else>{{ truncatedDetails(cons.details) }}</span>
                          <span v-if="cons.details.length > truncLen">
                            <a v-if="cons.showFullDetails" href="#!" @click.prevent="toggleDetails(cons)" class="link-more">...أقل</a>
                            <a v-else href="#!" @click.prevent="toggleDetails(cons)" class="link-more">...المزيد</a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="more-consult wow fadeInUp">
            <a @click="moreResults" href="#!" class="fixall">
              اظهر المزيد
            </a>
          </div>
        </div>
        <div class="consultations-search">
          <div class="consult-search">
            <h3>البحث عن الاسئلة والاستشارات</h3>
            <div class="search-bar">
              <input class="searchinput" id="search" placeholder="ابحث عن سؤالك او استشارة" type="search" v-model="filter.name">
              <button class="se_on" type="submit"><i class="fas fa-search" @click="filterByName"></i></button>
            </div>
            <!-- <a href="#!" class="order-consult">اطلب استشارة</a> -->
          </div>
          <div class="consult-most">
            <!-- <h3>الأكثر مشاهدة</h3> -->
            <h3>أحدث الاستشارات</h3>
            <div class="consult-most-title" v-for="(topCons, index) in topConsultations" :key="index">
              <h4>{{ topCons.title }}</h4>
              <p>
                <span v-if="topCons.showTopFullDetails">{{ topCons.details }}</span>
                <span v-else>{{ truncatedDetails(topCons.details) }}</span>
                <span v-if="topCons.details.length > truncLen">
                  <a v-if="topCons.showTopFullDetails" href="#!" @click.prevent="toggleDetails(topCons)">...أقل</a>
                  <a v-else href="#!" @click.prevent="toggleDetails(topCons)">...المزيد</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import PageHeadComponent from "@/components/PageHeadComponent.vue";
import {mapState} from "vuex";
import store from '@/store';
import {API_URL} from '@//helpers/config.js';
import ApiRequest from "@/helpers/apiRequest";
import { WOW } from 'wowjs'; // Import the WOW class
import 'animate.css';

export default {
  name: "ConsultationsPage",
  components: {
    PageHeadComponent
  },
  data() {
    return {
      pageTitle: "الإستشارات والأسئلة",
      consultations: [],
      topConsultations: [],
      resultsNo: 10,
      resultsInc: 10,
      truncLen: 100,
      wow: null,
      filter: {
        name: "",
      },
    };
  },
  methods: {
    getConsultancies(updateLatest = false) {
        store.commit('setIsLoading', true);
        ApiRequest.get(API_URL + "/cons-questions?count=" + this.resultsNo + "&filter=" + this.jsonFilter + "&sort=" + this.jsonSort)
            .then(response => {
              const originalData = response.data.data;
              this.consultations = originalData.map(cons => ({...cons, isOpen: false, showFullDetails: false }));
              this.topConsultations = updateLatest? originalData.slice(0, 3).map(tCon => ({...tCon, showTopFullDetails: false })) : this.topConsultations;
              store.commit('setIsLoading', false);
              this.syncWOW();
            })
            .catch(error => {
            console.log(error);
            store.commit('setIsLoading', false);
            });
    },
    truncatedDetails(text) {
      return text.substring(0, this.truncLen); // adjust the length to your liking
    },
    toggleDetails(consultation) {
      if('showFullDetails' in consultation){
        consultation.showFullDetails =!consultation.showFullDetails;
      }else{
        consultation.showTopFullDetails =!consultation.showTopFullDetails;
      }
    },
    toggleFaq(idx) {
      this.consultations[idx].isOpen = !this.consultations[idx].isOpen;
    },
    filterByName(){
      this.getConsultancies();
    },
    moreResults(){
      this.resultsNo += this.resultsInc;
      this.getConsultancies();
    },
    initializeWOW() {
      const libraryContainer = this.$refs.libraryContainer;
      // Initialize the WOW library
      this.wow = new WOW({
        live: false,
      });
      this.wow.init(libraryContainer);
    },
    syncWOW() {
      if (this.wow) {
        this.wow.sync();
      } else {
        this.initializeWOW();
      }
    },
  },
  updated() {
    this.syncWOW(); // Call syncWOW() after dynamic content update
  },
  computed: {
    ...mapState(["isLoading"]),
    jsonFilter(){
      return JSON.stringify(this.filter);
    },
  },
  watch: {
    consultations: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.syncWOW();
        });
      },
    },
  },
  mounted() {
    this.getConsultancies(true);
    this.$nextTick(() => {
      this.syncWOW();
    });
  },
}
</script>

<style>
.cons-enter-from {
  opacity: 0;
}
.cons-enter-to {
  opacity: 1;
}
.cons-enter-active {
  transition: all 0.5s ease;
}
.cons-leave-from {
  opacity: 1;
}
.cons-leave-to {
  opacity: 0;
}
.cons-leave-active {
  transition: all 0.5s ease;
}
</style>